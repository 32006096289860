@import "custom";
@import "darkmode";

body {
  margin: 12px 20px;
}

/* extend bootstrap run for wider displays */
@media (min-width: 1600px) {
  body .container {
    max-width: 1540px;
  }
}

.container {
  margin: auto;
  overflow: hidden;
  padding: 0 15px;
  width: 100%;
}

textarea {
  font-family: monospace;
  max-width: 876px;
  width: 100%;
}

.panel {
  border-color: grey;
  border-style: dotted;
  border-width: 1px 1px 1px 1px;
  font-size: 120%;
  padding: 0 8px;
  p {
    color: #bdbdbd;
  }
}

#panel_encoded > p > .length,
#header > p > .length,
#payload > p > .length,
#directkey > p > .length,
#symmetrickey > p > .length {
  font-size: 60%;
}

#directkey,
#symmetrickey {
  > input {
    background-color: white; /* must be explicit to allow flash? */
    font-family: monospace;
    font-size: 85%;
    padding-left: 6px;
    width: 100%;
  }
  > div {
    margin-left: 32px;
    span {
      color: #bdbdbd;
      display: inline-block;
      font-size: 80%;
      width: 140px;
    }
    input {
      font-family: monospace;
      margin-left: 8px;
      font-size: 85%;
      padding-left: 6px;
      width: 440px;
    }
    select {
      display: inline-block;
      margin-left: 8px;
      width: 180px;
    }
  }
}

.panel-left {
  float: left;
  width: 520px;
  min-height: 720px;
  margin: 2px 0;
}

.panel-center {
  border-width: 0;
  float: left;
  margin: 2px 0;
  width: 156px;
  > button {
    margin-top: 14px;
  }
  #chk-iat {
    width: auto;
  }
  > * {
    width: 140px;
  }
  > select:not(:first-child) {
    margin-top: 14px;
  }
  .form-control-sm {
    padding-left: 0.25rem;
  }
  > div:first-of-type {
    margin-top: 20px;
    text-transform: uppercase;
  }
  > div {
    font-size: 60%;
    margin-right: 6px;
  }
  > div:has(> #chk-darkmode) {
    margin-top: 20px;
    text-transform: uppercase;
    font-size: 70%;
  }
  > div > input[type="checkbox"] {
    top: 4px;
    position: relative;
  }
}

.panel-right {
  margin-left: 678px;
  min-width: 452px;
}

div.CodeMirror {
  border: 1px dotted #ccc;
  height: auto;
}

pre.CodeMirror-line > span {
  /* all below, to handle really long JWTs */
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* css-3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
  word-break: break-all;
  /* white-space: normal; */
}

.alert-dismissible {
  padding-right: 16px;
}

.alert-dismissible > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  /*    margin-left: 24px; */
}

.dismiss {
  border: 1px solid #696969;
  border-radius: 4px;
  padding: 4px 8px;
  margin-left: 12px;
}

.rawvalue {
  max-width: 1024px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  margin: 0 0 8px 0;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 2rem;

  /* all below, to handle really long JWTs */
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* css-3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
  word-break: break-all;
  white-space: normal;
}

.cm-jwt-header {
  color: #fa003a;
}

.cm-jwt-payload {
  color: #b100ff;
}
.cm-jwt-iv {
  color: #ff948a;
}
.cm-jwt-key {
  color: #89bb86;
}
.cm-jwt-signature,
.cm-jwt-authtag {
  color: #3184fb;
}

#privatekey,
#publickey {
  > .CodeMirror {
    font-size: 84%;
    max-height: 232px;
  }
}

#privatekey .CodeMirror-scroll {
  max-height: 162px;
}

#publickey .CodeMirror-scroll {
  max-height: 216px;
}

#panel_decoded > div {
  margin-bottom: 18px;
}

.banner {
  margin-bottom: 24px;
  background-color: #dfdddd;
  height: 138px;
}

.banner > .alert {
  margin: 0;
  z-index: 10;
}

.btn-copy,
.btn-clear,
.btn-newkey,
.btn-newjson {
  padding: 2px 6px;
  margin: 0 4px;
  float: right;
  top: 8px;
  position: relative;
}

.banner,
.footer {
  border: 1px dotted grey;
}

.footer {
  margin-top: 24px;
  background-color: #ececec;
  padding: 0 4px;
  min-height: 120px;
}

.banner > h2,
.banner > div {
  margin: 0;
  position: absolute;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.banner > h2,
.footer > h2 {
  top: 46px;
}

.banner > div {
  top: 96px;
}

.banner > div#buildversion {
  top: 118px;
  left: 0%;
  position: relative;
  margin-right: 6px;
  transform: none;
  text-align: right;
  font-size: 75%;
  font-style: italic;
}

.alert.fade {
  opacity: 0;
  transition: opacity 0.85s linear;
}

.copy-to-clipboard-flash-bg {
  animation-name: flashbg;
  animation-timing-function: ease-out;
  animation-duration: 1s;
}

@keyframes flashbg {
  0% {
    background: #539edb;
  }
  100% {
    background: transparent;
  }
}

.outdated {
  /* indicates that the keypair is not known to correspond to the JWT/JWE */
  background-color: #dcdcdc;
}

#symmetrickey input[disabled] {
  color: #636c77;
  background-color: #e9ecef;
}

.popover,
.popover-body {
  --bs-popover-max-width: auto;
}

.popover.bs-popover-right {
  /* max-width: 580px; */
  background-color: #dedede;
}

.bs-popover-right .arrow::after {
  border-right-color: #dedede !important;
}

.hidden {
  display: none;
}

.show {
  display: auto;
}
