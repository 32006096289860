body.dark-mode {
  background-color: #121212;
  color: #e0e0e0;

  .panel,
  .form-control,
  .form-control:focus,
  .modal-content,
  .modal-header,
  .modal-footer,
  .alert,
  .badge,
  .btn,
  .btn:focus,
  .btn:active,
  .btn:active:focus,
  .btn:active:hover,
  .banner,
  .footer {
    background-color: #333;
    color: #fff;
  }

  .panel.panel-center {
    background-color: #121212;
  }

  #directkey,
  #symmetrickey {
    > input {
      background-color: #333;
      color: #fff;
    }
  }
  #pbkdf2_params input {
    background-color: #333;
    color: #fff;
  }

  .alert .close,
  .alert .close:hover {
    color: #fff;
  }

  .btn:hover {
    background-color: #555;
    color: #fff;
  }

  .banner a {
    color: lightblue;
  }

  .footer a {
    color: lightblue;
  }

  .outdated {
    background-color: #000;
    color: #fff;
  }

  .dismiss {
    border: 1px solid #696969;
    background-color: #636363;
  }
}

/* Based on Sublime Text's Monokai theme */

.cm-s-monokai {
  &.CodeMirror {
    background: #272822;
    color: #f8f8f2;
  }
  div.CodeMirror-selected {
    background: #49483e;
  }

  .CodeMirror-line::selection,
  .CodeMirror-line > span::selection,
  .CodeMirror-line > span > span::selection {
    background: rgba(73, 72, 62, 0.99);
  }

  .CodeMirror-line::-moz-selection,
  .CodeMirror-line > span::-moz-selection,
  .CodeMirror-line > span > span::-moz-selection {
    background: rgba(73, 72, 62, 0.99);
  }

  .CodeMirror-gutters {
    background: #272822;
    border-right: 0px;
  }

  .CodeMirror-guttermarker {
    color: white;
  }

  .CodeMirror-guttermarker-subtle {
    color: #d0d0d0;
  }

  .CodeMirror-linenumber {
    color: #d0d0d0;
  }

  .CodeMirror-cursor {
    border-left: 1px solid #f8f8f0;
  }

  span.cm-comment {
    color: #75715e;
  }

  span.cm-atom,
  span.cm-number {
    color: #ae81ff;
  }

  span.cm-comment.cm-attribute {
    color: #97b757;
  }

  span.cm-comment.cm-def {
    color: #bc9262;
  }

  span.cm-comment.cm-tag {
    color: #bc6283;
  }

  span.cm-comment.cm-type {
    color: #5998a6;
  }

  span.cm-property,
  span.cm-attribute {
    color: #a6e22e;
  }

  span.cm-keyword {
    color: #f92672;
  }

  span.cm-builtin {
    color: #66d9ef;
  }

  span.cm-string {
    color: #e6db74;
  }

  span.cm-variable {
    color: #f8f8f2;
  }

  span.cm-variable-2 {
    color: #9effff;
  }

  span.cm-variable-3,
  span.cm-type {
    color: #66d9ef;
  }

  span.cm-def {
    color: #fd971f;
  }

  span.cm-bracket {
    color: #f8f8f2;
  }

  span.cm-tag {
    color: #f92672;
  }

  span.cm-header,
  span.cm-link {
    color: #ae81ff;
  }

  span.cm-error {
    background: #f92672;
    color: #f8f8f0;
  }

  .CodeMirror-activeline-background {
    background: #373831;
  }

  .CodeMirror-matchingbracket {
    text-decoration: underline;
    color: white !important;
  }
}
